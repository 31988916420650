<app-loading></app-loading>
<div *ngIf="!isProduction" class="testHighlight">
<h5 class="text-center font-weight-bolder" style="color: #fff;"> للفحص فقط </h5>
</div>


<!-- vertical-layout -->
<ng-container *ngIf="coreConfig.layout.type === 'vertical'">
    <vertical-layout></vertical-layout>
</ng-container>
<!-- / vertical-layout -->

<!-- horizontal-layout -->
<ng-container *ngIf="coreConfig.layout.type === 'horizontal'">
    <horizontal-layout></horizontal-layout>
</ng-container>
<!-- / horizontal-layout -->
