import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';

export interface AppConfig {
  baseUrl: string;
  production: boolean;
  webUrl: string;
  appName: string;
  version: string;
}

@Injectable({
  providedIn: 'root'
})
export class ConfigService {
  private config: AppConfig | null = null;
  private configSubject = new BehaviorSubject<AppConfig | null>(null);
  config$ = this.configSubject.asObservable();

  constructor(private http: HttpClient) { }

  loadConfig(): Promise<void> {
    return new Promise((resolve, reject) => {
      this.http.get<AppConfig>('/assets/config.json').pipe(
        take(1)  // Ensure we only take one value
      ).subscribe({
        next: (config) => {
          this.config = config;
          this.configSubject.next(config);
          resolve();
        },
        error: (error) => {
          console.error('Failed to load config:', error);
          reject(error);
        }
      });
    });
  }

  getBaseUrl(): string {
    return this.config?.baseUrl || '';
  }

  getWebUrl(): string {
    return this.config?.webUrl || '';
  }

  getAppName(): string {
    return this.config?.appName || '';
  }

  getVersion(): string {
    return this.config?.version || '';
  }

  isProduction(): boolean {
    return this.config?.production || false;
  }
}
