import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { CoreDirectivesModule } from '@core/directives/directives';
import { CorePipesModule } from '@core/pipes/pipes.module';
import { CustomeraSelectComponent } from './components/ng-selects/units-select/customers-select.component';
import { NgSelectModule } from '@ng-select/ng-select';

@NgModule({
  declarations: [CustomeraSelectComponent],
  imports: [CommonModule, FlexLayoutModule, FormsModule, ReactiveFormsModule, CoreDirectivesModule, CorePipesModule,NgSelectModule],
  exports: [CommonModule, FlexLayoutModule, FormsModule, ReactiveFormsModule, CoreDirectivesModule, CorePipesModule,CustomeraSelectComponent]
})
export class CoreCommonModule {}
