import {
  Component,
  EventEmitter,
  forwardRef,
  Input,
  Output,
  SimpleChanges,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { CustomersService } from '@core/services/customers.service';
import { CustomerFilterData } from '@core/types/customers';

@Component({
  selector: 'app-customers-select',
  templateUrl: './customers-select.component.html',
  styleUrls: ['./customers-select.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CustomeraSelectComponent),
      multi: true,
    },
  ],
})
export class CustomeraSelectComponent implements ControlValueAccessor {
  @Input() bindLabel: string = 'name';
  @Input() bindValue: string = 'id';
  @Input() unitType: any = null;
  @Input() id: string = '';
  @Input() multiple: boolean = false;

  @Output() modelChange = new EventEmitter<boolean>();

  selectedItem: any;
  searchForm: any = { pageNumber: 1, pageSize: 10, name: '', filterParam: {} };
  customers: any[] = [];
  customersLoading: boolean = false;
  filterParam = {} as CustomerFilterData;
  constructor(private _customersService: CustomersService) { }

  writeValue(value: any): void {
    this.selectedItem = value;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  ngOnInit(): void {
    this.getUnits();
  }

  // In your UnitsSelectComponent class, add a method to get the title
  getSelectedTitle(): string {
    if (!this.selectedItem || !this.customers) return '';

    if (this.multiple) {
      // For multiple selection, find all selected items and join their names
      const selectedUnits = this.customers.filter(unit =>
        this.selectedItem.includes(unit[this.bindValue])
      );
      return selectedUnits.map(unit => unit[this.bindLabel]).join(', ');
    } else {
      // For single selection, find the selected item
      const selectedUnit = this.customers.find(unit =>
        unit[this.bindValue] === this.selectedItem
      );
      return selectedUnit ? selectedUnit[this.bindLabel] : '';
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['id']) {
      const idValue = changes['id'].currentValue;

      this.handleIdChange(idValue);
    }
  }

  handleIdChange(id: string | null): void {
    if (id != null) {
      this.getUnitById(id);
    } else {
      this.selectedItem = null;
    }
  }
  getUnits() {
    this.customersLoading = true;
    this._customersService.getCustomers(this.searchForm.pageNumber, this.searchForm.pageSize,this.filterParam).subscribe(
      (res: any) => {
        this.customers = [...this.customers, ...res.items];
        this.customersLoading = false;
      },
      () => (this.customersLoading = false)
    );
  }



  selectingItem(e: any) {
    if (this.multiple) {
      this.selectedItem = e ? e.map((item: any) => item[this.bindValue]) : [];
    } else {
      this.selectedItem = e ? e[this.bindValue] : null;
    }
    this.onChange(this.selectedItem);

    if (e)
      this.modelChange.emit(e);
  }

  onClear() {
    this.selectedItem = null;
    this.selectingItem(null);
    this.modelChange.emit(null);
  }


  onSearch(e: any) {
    this.filterParam.searchBy = "name";
    this.filterParam.searchText = e ? e.term : null;

    this.searchForm.pageNumber = 1;
    this.customers = [];
    this.getUnits();
  }

  paginate() {
    this.searchForm.pageNumber += 1;
    this.getUnits();
  }

  getUnitById(id: any) {
    this._customersService.getCustomer(id).subscribe((res: any) => {
      this.customers = res?.items;
      this.writeValue(res?.data.id);
    });
  }

  private onChange = (value: any) => { };
  private onTouched = () => { };
}
