<ng-select
  (search)="onSearch($event)"
  (change)="selectingItem($event)"
  placeholder="إبحث عن الزبون"
  [bindLabel]="bindLabel"
  [bindValue]="bindValue"
  [items]="customers"
  [loading]="customersLoading"
  [loadingText]="'يرجى الإنتظار..'"
  (scrollToEnd)="paginate()"
  [(ngModel)]="selectedItem"
  [multiple]="multiple"
  [title]="getSelectedTitle()"
  (clear)="onClear()"

>
</ng-select>
