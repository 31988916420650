import { Component, Inject } from '@angular/core';
import { LoadingService } from 'app/@core/services/loading.service';

@Component({
  selector: 'app-loading',
  template: `
    <div class="loading-overlay" *ngIf="loading$ | async">
      <div class="spinner"></div>
    </div>
  `,
  styles: [`
    .loading-overlay {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0.5);
      z-index: 9999;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .spinner {
      width: 50px;
      height: 50px;
      border: 5px solid #f3f3f3;
      border-radius: 50%;
      border-top: 5px solid #7367f0;
      animation: spin 1s linear infinite;
    }

    @keyframes spin {
      0% { transform: rotate(0deg); }
      100% { transform: rotate(360deg); }
    }
  `]
})
export class LoadingComponent {
  loading$ = this.loadingService.loading$;

  constructor(@Inject(LoadingService) private loadingService: LoadingService) {}
}
